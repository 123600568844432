
<template>
<div class="pb-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Sell Request</h5>
      <div v-if="item">
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10">
                <div class="row g-2  gx-sm-5">
                  <div class="col-12"> 
                    <div class="friendly-id mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                      </svg>
                      {{item.friendly_id}}
                    </div>
                  </div> 
                </div>
              </div>
              <div class="col-2 text-sm-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="popupModalUpdate = true" class="dropdown-item" type="button" >
                      Update status </button>
                    </li>
                    <li>
                      <button @click.prevent="deleteItem()" class="dropdown-item" type="button" >
                      Delete Request </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row g-2  gx-sm-5">
              <div class="col-12"> 
                <span class="me-2">Status:</span>
                <span v-html="statusBadge(item.status)"> </span>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">First name:</span>
                <strong> {{item.first_name}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Last name:</span>
                <strong> {{item.last_name}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Phone:</span>
                <strong> {{item.mobile}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Email:</span>
                <strong> {{item.email}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Date:</span>
                <strong> {{$filters.date(item.created_at)}} </strong>
              </div>
              <div v-if="item.device" class="col-12"> 
                <div class="pt-5">
                    <h5 class="border-bottom pb-2">Device details</h5>
                    <div class="mb-4 d-sm-flex align-items-center justify-content-start">
                        <div class="me-sm-3">
                            <img v-if="item.device.image" style="width:3rem;" :src="absoluteUrl(item.device.image)" />
                            <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                                <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                                    {{item.device.model.charAt(0).toUpperCase()}}
                                </div>
                            </div>
                        </div> 
                        <div class="">
                            <p class="mb-1"><strong>Model:</strong> {{item.device.model}}</p>
                            <div class="">
                                <span v-if="item.device.brand" class="me-3"> <strong>Make: </strong> {{item.device.brand.title}}</span>
                                <span v-if="item.device.category"> <strong>Type: </strong> {{item.device.category.title}}</span>
                            </div>
                            <p v-if="item.device_condition" class="mb-0"><strong>Device Condition:</strong> <span class="text-uppercase"> {{item.device_condition}} </span></p>
                        </div>
                    </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6>Selected Specifications</h6>
            <div class="table-responsive">
              <table class="table table-nowrap mb-0">
                <tbody>
                  <tr v-for="(attribute, atrIndex) in item.cart.attributes"
                    :key="atrIndex" >
                      <td class="">{{attribute.name}} </td>
                      <td class=" text-end"><strong>{{attribute.value }}</strong> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else  />
  <b-modal hide-footer v-model="popupModalUpdate" title="Update status">
    <sell-request-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="item" @updateItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import SellRequestForm from "@/components/forms/SellRequestForm.vue"

export default {
  components: { 
    IsLoading,
    SellRequestForm
  },
  data(){
    return {
      isLoading: true,
      popupModalUpdate: false,
      item: null
    }
  },
  watch: {
    '$route.params.requestId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/sell-requests/${this.$route.params.requestId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem() {
      this.confirmDelete({
        text: "You are about to delete this request",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/sell-requests/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/sell-requests'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalUpdate = false;
    },
  },
  created(){
    this.fetchItem()
  }
}

</script>

<style lang="scss">

</style>
